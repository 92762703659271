
<template>
 <div class="contact-box">
   <p>Kontaktanfrage erfolgreich verschickt.</p>
   <p>Sie erhalten von mir schnellstmöglich eine Rückmeldung</p>
 </div>

</template>

<script setup>

</script>
<style scoped lang="sass">

  .contact-box
    display: block
    position: relative
    padding-top: 2em
    width: 40vw
    height: 20vh
    text-align: center
    align-items: center
    color: #f1f1f1
    background: rgba(101, 128, 212, 0.27)
    box-shadow: 5px 5px 20px 5px rgba(101, 128, 212, 0.45)
    border-radius: 25px
    border: #7898FB 1px solid
    transition: 0.6s

    z-index: 10

</style>